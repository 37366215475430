import React from "react";

const Contact = () => {
  return (
    <>
      <span id="contact"></span>
      <div data-aos="zoom-in" className="dark:bg-gradient-to-r from-black to-yellow-900 dark:text-[#fff] text-[#000] py-14 flex justify-center self-center items-center text-center">
        <div className="container  ">
          <div className="grid lg:grid-cols-3 gap-6 justify-center self-center items-center py-0 px-2">
            <div className="col-span-2 space-y-3 text-start">
              <h1 className="text-4xl sm:text-5xl font-bold">
              VFA partners with artists to create high-quality, timeless wearables that merge art with innovative technology. Apply now to bring your vision to life!
                            </h1>
              {/* <p className="">
              Our garments feature heavyweight fabrics, boxy fits, and tonal washed colors, ensuring seamless integration with your wardrobe. Experience our unique 'wear to earn' feature with embedded NFC technology, blending style with innovation. Elevate your fashion game with VFA Gallery.
              </p> */}
            </div>
            <div className="mt-5 justify-center self-center items-center">
              <a
                 href="/form"
                //  href="https://sp.limoconnect247.net/webbookings/#booking/91cdf4bc186b4d91b78a927fbf382d96" target="_blank"
                 className="bg-black dark:bg-gradient-to-r from-blue-600 to-yellow-600 shadow-xl w-80 p-4 px-10 rounded-full text-white hover:scale-110 duration-500 font-semibold"
                //  className="bg-[#e9c0e9] shadow-xl w-80 p-5 rounded-full text-[#502274] hover:scale-110 duration-500 font-semibold"
                // className="inline-block font-semibold py-5 px-10 self-center rounded-full bg-gradient-to-r from-violet-600 to-indigo-600 text-white hover:bg-primary/80 duration-200 tracking-widest uppercase "
              >
                Apply Here
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
