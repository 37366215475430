import React, { useEffect, useState } from "react";
import { useCart } from "react-use-cart";
import { MdOutlineAddShoppingCart } from "react-icons/md";
import { Link } from "react-router-dom";
import firebase from "firebase/compat/app";
import firebaseConfig from "../firebaseConfig";
import "firebase/compat/firestore";

const ProductCard = (props) => {
  let { product_image, price, descriprion, title, id, name } = props.data;

  const [productData, setProductData] = useState([]);

  useEffect(() => {
    async function getResponse() {
      // Initialize Firebase
    // Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAvfSZiORsVohwIB696oyJYwxBcuh-jRRY",
  authDomain: "markers-digital-pro.firebaseapp.com",
  projectId: "markers-digital-pro",
  storageBucket: "markers-digital-pro.appspot.com",
  messagingSenderId: "99311584893",
  appId: "1:99311584893:web:63167d001bb5c569f31314",
  measurementId: "G-H43QCYF1XV"
};

      if (!firebase.apps.length) {
        firebase.initializeApp(firebaseConfig);
      }

      const db = firebase.firestore();
      const collection = db.collection("Products");
      const snapshot = await collection.get();
      const Products = [];

      snapshot.forEach((doc) => {
        Products.push({
          id: doc.id,
          ...doc.data(),
        });
      });

      setProductData(Products);
    }

    getResponse();
  }, []);

  const { addItem } = useCart();

  const addToCart = () => {
    addItem(props.data);
  };

  const [oku, setOku] = useState(0)

  return (
    <div
      key={id}
      // style={{opacity: 0.5}}
      // onMouseEnter={() => setOku(1)}
      // onMouseLeave={() => setOku(0)}
      // onTouchStart={() => setOku(1)}
      // onTouchEnd={() => setOku(0)}
      className="text-center border-0 dark:border-[#333] items-center justify-center lg:m-2 m-1"
    >
      {/* <Link to={{
  pathname: `/product-details/${id}`,
  state: { name: "Hmmmmmm Data" }
}} */}
       <Link to={`/product-details/${id}?name=${name}`} 
      // <Link to={`/product-details/${id}`} 
      onClick={() => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
      }}  
      className="self-center"
      >
        <div>
          <div 
          // className="w-[60%]"
          >
            {/* <img src={product_image[oku]} alt="logo" className="mb-2 dark:shadow-2xl dark:bg-transparent bg-[#f4f4f4]" /> */}
            <img src={product_image[oku]} alt="logo" className="mb-2 dark:shadow-2xl rounded-2xl dark:bg-transparent bg-[#000]" />
          </div>
        </div>
        <p className="text-sm dark:text-[#ccc] text-[#555] lg:px-10 px-2">
          {title}
        </p>
        <p className="text-xs px-10 py-2 dark:text-white font-bold">
          ${price}
        </p>
        {/* <p className="text-xs dark:text-[#ccc] text-[#555] pb-5 tracking-wide">
        XS &nbsp; S &nbsp; M &nbsp; L &nbsp; XL &nbsp; XXL
        </p> */}

        {/* <p className="text-sm text-gray-600 px-10">
          {descriprion}
        </p> */}
      </Link>

      {/* <div className="text-center text-white">
        <h1>{title}</h1>
        <h1>
          $. <span className="text-white">{price}</span>
        </h1>
        <button onClick={() => addToCart()}>
          <MdOutlineAddShoppingCart
            size="1.8rem"
            className="text-center ml-[30%] text-green-600"
          />
          Add to cart
        </button>
      </div> */}
    </div>
  );
};

export default ProductCard;
