import React, { useEffect, useState } from "react";
import { Button, Upload, message, Tooltip } from "antd";
import { FaArrowCircleUp } from "react-icons/fa";
import { Link, useNavigate, useParams } from "react-router-dom";
import { collection, doc, getDoc, getDocs, addDoc, onSnapshot, orderBy, query, updateDoc, where } from "firebase/firestore";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { db, storage, auth } from "../firebaseConfig";
import { CopyOutlined, ShareAltOutlined } from "@ant-design/icons";
import { BiArrowToRight } from "react-icons/bi";
import { FaArrowRight } from "react-icons/fa6";
import Shop from "./Shop";
import { BsCart } from "react-icons/bs";
import firebase from "../firebaseConfig";
import Homes from "./Homes";

// import 'antd/dist/antd.css'; // Import Ant Design styles

function PublicProfile() {
    const {name} = useParams();

    const [profileData, setProfileData] = useState(null);
    const [CprofileData, setCProfileData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [profileImage, setProfileImage] = useState(null);
    const currentUser = auth.currentUser;
    const currentURL = `${window.location.origin}/`;
    const [inputValue, setInputValue] = useState("");

  const [linksData, setLinksData] = useState([]);

  
  
  const [user, setUser] = useState(null);

  const [CSuser, setCSUser] = useState();
  useEffect(() => {
    
    // Check if user data exists in local storage
    const userData = localStorage.getItem("userData");
    if (userData) {
      setUser(JSON.parse(userData));
    }
  }, []);

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe(); // Cleanup the event listener on unmount
  }, []);


     // fetch the user data from firebase
     useEffect(() => {
      const subscriber = db
          .collection('userss')
          .doc(currentUser?.uid)
          .onSnapshot(documentSnapshot => {
            setCProfileData(documentSnapshot.data());
          });
      
      // Stop listening for updates when no longer required
      return () => subscriber();
    }, [currentUser?.uid]);

  useEffect(() => {
    if (!name) return;

    const logActivity = async () => {
      try {
        const visitorEmail = auth.currentUser?.email || "Anonymous";
        const activityData = {
          username: name,
          timestamp: new Date(),
          visitorEmail,
          pageVisited: currentURL + name,
          image: CprofileData?.profileImage || "https://static.vecteezy.com/system/resources/thumbnails/005/129/844/small_2x/profile-user-icon-isolated-on-white-background-eps10-free-vector.jpg",
          vuname: CprofileData?.username || "Anonymous"
        };
        await addDoc(collection(db, "Activity"), activityData);
      } catch (error) {
        console.error("Error logging activity:", error);
      }
    };

    const fetchUserData = async () => {
      try {
        const usersCollectionRef = collection(db, "userss");
        const userQuery = query(usersCollectionRef, where("username", "==", name));
        const querySnapshot = await getDocs(userQuery);

        if (!querySnapshot.empty) {
          const userDoc = querySnapshot.docs[0];
          const linksCollectionRef = collection(userDoc.ref, "Links");
          const orderedLinksQuery = query(linksCollectionRef, orderBy("createdAt", "asc"));

          onSnapshot(userDoc.ref, (docSnapshot) => {
            setProfileData(docSnapshot.data());
          });

          onSnapshot(orderedLinksQuery, (snapshot) => {
            const links = snapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));
            setLinksData(links);
          });
        } else {
          console.error("User not found with username:", name);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    logActivity();
    fetchUserData();
  }, [name, currentURL]);

  console.log("Oku Okon Akpabio", CprofileData?.profileImage, CprofileData, CprofileData?.username)

  console.log(name, profileData, linksData)
  const navigate = useNavigate();


    const handleShare = () => {
        if (navigator.share) {
          navigator.share({
            title: `${name}'s Profile`,
            url: `${currentURL}${name}`,
          })
          .then(() => message.success("Profile shared successfully!"))
          .catch((error) => message.error("Error sharing profile: " + error));
        } else {
          message.warning("Share feature not supported on this browser.");
        }
      };

    const handleShop = () => {
          navigate("/#shop") 
      };

      const [Activity, setActivity] = useState([]);

      useEffect(() => {
        if (!profileData?.username) return; // Avoid running if username is not available
    
        const usersCollectionRef = collection(db, "Activity");
        const userQuery = query(
          usersCollectionRef,
          where("username", "==", profileData?.username),
          orderBy("timestamp", "desc") // Add order by for sorting
        );
    
        const unsubscribe = onSnapshot(userQuery, (snapshot) => {
          const filteredActivity = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setActivity(filteredActivity);
        });
    
        return () => unsubscribe(); // Cleanup on component unmount or dependency change
      }, [profileData?.username]);

      const handleCopyLink = () => {
        const profileLink = `${currentURL}${name}`;
        navigator.clipboard.writeText(profileLink);
        message.success("Profile link copied to clipboard!");
      };

      const [friendStatus, setFriendStatus] = useState("not_friends"); // 'not_friends', 'request_sent', 'friends'
      const [friendProfile, setFriendProfile] = useState(null); // To store the friend's profile
      // const [loading, setLoading] = useState(false); // Manage loading state
      
      useEffect(() => {
        const fetchFriendStatus = async () => {
          if (!currentUser?.uid || !profileData?.uid) return;
      
          try {
            const currentUserRef = db
              .collection("userss")
              .doc(currentUser?.uid)
              .collection("friends")
              .doc(profileData?.uid);
      
            const friendDoc = await currentUserRef.get();
            if (friendDoc.exists) {
              const data = friendDoc.data();
              setFriendStatus(data.status === "pending" ? "request_sent" : "friends");
              setFriendProfile(data); // Store the friend's profile if it exists
            } else {
              setFriendStatus("not_friends");
            }
          } catch (error) {
            console.error("Error fetching friend status: ", error);
          }
        };
      
        fetchFriendStatus();
      }, [currentUser?.uid, profileData?.uid]);
      
      const sendFriendRequest = async () => {
        setLoading(true);

        !user && navigate("/register") 
      
        try {
          const viewedUserRef = db
            .collection("userss")
            .doc(profileData?.uid)
            .collection("friends")
            .doc(currentUser.uid);
      
          // Add friend request in both directions
          await viewedUserRef.set({
            displayName: CprofileData?.username,
            photoURL: CprofileData?.profileImage,
            owner_uid: profileData?.uid,
            uid: currentUser?.uid,
            position: currentUser?.position || "Unknown",
            createdAt: new Date(),
            status: "pending",
          });
      
          setFriendStatus("request_sent");
          console.log("Friend request sent successfully");
        } catch (error) {
          console.error("Error sending friend request: ", error);
        } finally {
          setLoading(false);
        }
      };
      
      const acceptFriendRequest = async () => {
        setLoading(true);
      
        try {
          const currentUserRef = db
            .collection("userss")
            .doc(currentUser.uid)
            .collection("friends")
            .doc(profileData?.uid);
      
          const viewedUserRef = db
            .collection("userss")
            .doc(profileData?.uid)
            .collection("friends")
            .doc(currentUser.uid);
      
          // Update both users' friendship status to "friends"
          await currentUserRef.update({ status: "friends" });
          await viewedUserRef.update({ status: "friends" });
      
          setFriendStatus("friends");
          console.log("Friend request accepted successfully");
        } catch (error) {
          console.error("Error accepting friend request: ", error);
        } finally {
          setLoading(false);
        }
      };
      
      const renderButton = () => {
        switch (friendStatus) {
          case "not_friends":
            return (
              <button
                onClick={sendFriendRequest}
                className="px-4 py-1.5 text-sm rounded-md bg-gradient-to-r from-violet-600 to-indigo-600 border-none text-white mx-2"
                disabled={loading}
              >
                {loading ? "Sending..." : "Add Friend"}
              </button>
            );
          case "request_sent":
            return (
              <button className="bg-gray-400 text-white px-4 py-1.5 rounded-md cursor-not-allowed mx-2">
                Request Sent
              </button>
            );
          case "friends":
            return (
              <button className="bg-gray-400 text-white px-4 py-1.5 rounded-md cursor-not-allowed mx-2">
                Friends
              </button>
            );
          default:
            return null;
        }
      };
      
      
  return (
    <>
    {/* <img className="fixed -z-0 h-screen w-screen lg:blur-2xl" src={profileData?.profileImage} alt="bg-image"  /> */}
   
    <div className="flex justify-center items-center m-4">
  {profileData?.profileCover ? (
             <img className="absolute -z-0 h-[55%] w-screen " src={profileData?.profileCover} alt="bg-image"  />
  ) : (
    <div
      style={{
        backgroundColor: profileData?.profileCoverColor,
      }}
      className="absolute -z-0 h-[55%] w-screen"
    />
  )}
</div>
    <div
      // style={{
      //   display: "flex",
      //   flexDirection: "column",
      //   justifyContent: "center",
      //   alignItems: "center",
      //   height: "100%",
      //   backgroundColor: "#f5efe4",
      //   textAlign: "center",
      // }}
      className="w-[100%] h-[100%] z-30 dark:bg-black items-center justify-center bg-[#f5efe4]"
    >

<div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        // backgroundColor: "#f5efe4",
        textAlign: "center",
      }}
      className="pt-24 lg:pt-28 z-30"
      >
        
        <div className="flex justify-center items-center mb-4 z-30">
          {profileData?.profileImage ? (
            <img
            src={profileData?.profileImage}
            alt="Profile"
            className="w-24 h-24 rounded-full object-cover"
            />
          ) : (
            <div className="w-24 h-24 rounded-full bg-gray-400 text-white flex items-center justify-center text-2xl">
              {name[0]?.toUpperCase()}
            </div>
          )}
        </div>

            <div className="flex items-center justify-center z-30 m-2">
      <h2 style={{ fontWeight: "bold" }} className="dark:text-white">{name}</h2>
      <div>{renderButton()}</div>
      <Button
            icon={<ShareAltOutlined />}
            type="primary"
            onClick={handleShare}
            className="bg-gradient-to-r from-violet-600 to-indigo-600 border-none text-white ml-2"
            // className="bg-[#8B5A2B] border-none text-white ml-2"
          >
            Share
          </Button>
      </div>
      {/* <p className="text-sm mb-2">{Activ} connections</p> */}
      <p className="text-sm mb-1">{Activity?.length} connections</p>
      <a
            // icon={<BsCart />}
            // type="primary"
            // onClick={handleShop}
            href="#shop"
            className="bg-gradient-to-r from-violet-600 to-indigo-600 border-none mb-5 text-sm items-center justify-center self-center flex w-60 py-2 rounded-lg text-white"
          >
            <BsCart />
            <p className="ml-2">
            Shop to credit {name}
            </p>
          </a>
      </div>

       {/* <Tooltip title="Copy Profile Link">
          <Button
            icon={<CopyOutlined />}
            type="text"
            onClick={handleCopyLink}
            className="text-black font-semibold w-full text-center"
          >
            {currentURL + name}
          </Button>
        </Tooltip> */}
      {/* <p style={{ color: "gray", marginBottom: "32px" }}>FUTURIST</p> */}
       {/* Profile Link Copy Section */}
    

       {/* <div className="grid grid-cols-3 gap-3 mb-4">
  {["Facebook", "Instagram", "PayPal", "TikTok", "Zelle", "LinkedIn", "CashApp", "X", "WhatsApp", "Youtube", "Link", "Link"].map((link, index) => (
    <button
      key={index}
      className="bg-gray-100 shadow-lg p-3 lg:px-10 px-6 rounded text-sm w-full text-black"
    >
      {link}
    </button>
    ))}
</div> */}


<div className="grid gap-3 m-4 ">
  {
  // [
  //           { name: "Instagram", image: "https://cdn2.iconfinder.com/data/icons/social-icons-33/128/Instagram-512.png" },
  //           { name: "PayPal", image: "https://cdn4.iconfinder.com/data/icons/logos-and-brands/512/250_Paypal_logo-512.png" },
  //           { name: "Facebook", image: "https://cdn1.iconfinder.com/data/icons/logotypes/32/square-facebook-512.png" },
  //           { name: "TikTok", image: "https://cdn0.iconfinder.com/data/icons/logos-brands-7/512/TikTok_logo_app0-512.png" },
  //           { name: "Zelle", image: "https://play-lh.googleusercontent.com/F4U2pL8z-Ic5FzCfe1xVXMWRvff6oEBIzDsyGRc4mE3bIUPiCfhuXXXvTOfcpVglKqs" },
  //           { name: "LinkedIn", image: "https://cdn2.iconfinder.com/data/icons/social-media-2285/512/1_Linkedin_unofficial_colored_svg-512.png" },
  //           { name: "CashApp", image: "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c5/Square_Cash_app_logo.svg/1200px-Square_Cash_app_logo.svg.png" },
  //           { name: "X", image: "https://freepnglogo.com/images/all_img/1725374683twitter-x-logo.png" },
  //           { name: "WhatsApp", image: "https://cdn2.iconfinder.com/data/icons/social-media-applications/64/social_media_applications_23-whatsapp-512.png" },
  //           { name: "Youtube", image: "https://cdn4.iconfinder.com/data/icons/logos-and-brands/512/395_Youtube_logo-512.png" },
  //           { name: "Link", image: "https://cdn2.iconfinder.com/data/icons/interface-vol-2-1/16/link-chain-hyperlink-web-512.png" },
  //           { name: "Link", image: "https://cdn2.iconfinder.com/data/icons/interface-vol-2-1/16/link-chain-hyperlink-web-512.png" },
  //         ]
          linksData.map((link, index) => (
    // <button
    //   key={index}
    //   className="bg-gray-800 p-3 rounded text-sm w-full text-white"
    // >
    //   {link}
    // </button>
    <>
    {
      link.url &&
    <div className="text-start flex items-center justify-center ">
     <Link
      to={link.url}
      onClick={() => link?.url ? "" : alert(`${name} dosen't have this profile yet`)}
       className="text-start w-[100%] items-center p-5 rounded-2xl justify-center dark:bg-[rgba(52,52,52,0.3)] dark:shadow-gray-900 shadow-2xl">
    <div className="flex text-start items-center justify-between mx-2">
    <div className="flex text-start items-center justify-between">
      <img src={link.image} alt="Logo" className="w-10" />
<p className="ml-2 dark:text-white font-bold">

      {link.name}
</p>
      </div>
<FaArrowRight className="dark:text-white" />
      </div>

    <div className=" text-start">

      {/* <Link
      to={link.url}
      onClick={() => link?.url ? "" : alert(`${name} dosen't have this profile yet`)}
      key={index}
      className="bg-gray-100 shadow-lg p-3 lg:px-10 px-6 dark:text-white dark:bg-[#111] rounded-2xl text-sm w-full text-black"
    >
      {link.name}
    </Link> */}

  </div>
  </Link>
  </div>
    }
    </>
    ))}
</div>

{
  !user &&
      <Button
        type="default"
        href="/register" // Replace with your actual link
        style={{
          backgroundColor: "#fff",
          color: "#000",
          padding: "10px 20px",
          borderRadius: "20px",
          fontWeight: "bold",
          textTransform: "none",
          margin: 30
        }}
      >
        Join {name} on Digital Twin
      </Button>
}


      <span id="shop"></span>
      <Homes title={"me"} name={name}/>
      {/* <div style={{ position: "fixed", bottom: "20px", right: "20px" }}>
        <FaArrowCircleUp size={40} color="#ffba00" />
      </div> */}
    </div>
    </>
  );
}

export default PublicProfile;
