import React, { useEffect, useState } from "react";
import { FaUserCircle } from "react-icons/fa";
import { Navlinks } from "./Data";
import { BiSolidMoon, BiSolidSun } from "react-icons/bi";
import { HiMail } from "react-icons/hi";
import { MdCall } from "react-icons/md";
import { auth, db, signInWithGoogle } from "../firebaseConfig";
import firebase from "../firebaseConfig";
import { Link, useNavigate } from "react-router-dom";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { useCart } from "react-use-cart";




const ResponsiveMenu = ({ showMenu, setShowMenu, theme, setTheme }) => {
  
  const navigate = useNavigate();
  const currentUser = auth.currentUser;


  const handleGoogleSignIn = async () => {
    const user = await signInWithGoogle();
    if (user) {
      // Handle signed-in user
      navigate("/profile");
      console.log("Signed in as:", user.displayName); 
    }
  };

  const [profileData, setProfileData] = useState(null);
  // fetch the user data from firebase
  useEffect(() => {
   const subscriber = db
       .collection('userss')
       .doc(currentUser?.uid)
       .onSnapshot(documentSnapshot => {
         setProfileData(documentSnapshot.data());
       });
   
   // Stop listening for updates when no longer required
   return () => subscriber();
 }, [currentUser]);
  
  const [user, setUser] = useState(null);

  const [CSuser, setCSUser] = useState();
  useEffect(() => {
    
    // Check if user data exists in local storage
    const userData = localStorage.getItem("userData");
    if (userData) {
      setUser(JSON.parse(userData));
    }
  }, []);

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe(); // Cleanup the event listener on unmount
  }, []);

  const { isEmpty, totalItems } = useCart();

  console.log("showMenu", showMenu);
  
  const handleSignOut = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        // Remove user data from local storage
        localStorage.removeItem("userData");

        setUser(null);
        navigate('/');
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" })

      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div
      className={`${
        showMenu ? "left-0" : "-left-[100%]"
      } fixed bottom-0 top-0 z-20 text-start flex h-screen w-[75%] flex-col justify-between bg-white dark:bg-gradient-to-r from-black to-yellow-900 dark:text-white px-8 pb-6 pt-10 text-black transition-all duration-200 md:hidden rounded-r-xl shadow-md`}
    >

      <div className="card">
        <Link
         to={user ? '/profile' : '/'}
         onClick={()=>setShowMenu(false)}
        className="flex items-center justify-start gap-3">
          {
            profileData?.profileImage ?
            <img src={profileData?.profileImage} alt="Profile Picture" className="h-20 w-20 rounded-full" />
            :
          <FaUserCircle size={50} />
          }
          <div>
            <h1>Hello</h1>
            {
        user ?
      //   <Link to='/profile' className="w-12 h-12 rounded-full bg-gray-400 text-white flex items-center justify-center text-2xl">
      //   {user?.displayName?.toUpperCase()}
      // </Link>
      <h1 className="text-sm text-slate-500">{user?.displayName?.toUpperCase()}</h1>

        :
        <h1 className="text-sm text-slate-500">Premium user</h1>
            }
          </div>
        </Link>
        <nav className="mt-10">
          <ul className="space-y-4 text-sm">
            {Navlinks.map((data) => (
              <li
              onClick={()=>setShowMenu(false)}
              >
                <a href={data.link} className="mb-5 inline-block text-sm">
                  {data.name}
                </a>
              </li>
            ))}
               <a href="mailto:info@lastwwwezzminutedispatch.com" >
        <HiMail className="text-2xl cursor-pointer" />
      </a>
                  <a href="tel:800-212356-18409" >
                    <MdCall className="text-2xl mt-5 cursor-pointer" />
                   </a>
                   {
                    user &&
                   <button
            onClick={handleSignOut}
            className=" bg-gradient-to-r from-blue-600 to-yellow-600 z-50 p-1 rounded-lg"
          >
                      <p className="px-12 py-1 text-sm text-white flex items-center" >
            Sign Out
            </p>
          </button>
                   }
          </ul>
        </nav>
      {/* <div className=' rounded-full p-2'>
          {theme === "dark" ? (
              <BiSolidSun onClick={() => setTheme("light")} className="text-2xl text-white cursor-pointer" />
                ) : (
             <BiSolidMoon onClick={() => setTheme("dark")} className="text-2xl cursor-pointer" />
          )}
      </div> */}
      </div>
         

      <div className="footer">
        <h1>
          Made with ❤ For <a href="/">
          You</a>{" "}
        </h1>
      </div>
    </div>
  );
};

export default ResponsiveMenu;
