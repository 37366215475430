import { useEffect, useState } from "react";
import { collection, getDocs, onSnapshot, query, orderBy, limit } from "firebase/firestore";
import { db } from "../firebaseConfig";
import { Link } from "react-router-dom";

const Leaderboard = () => {
  const [leaderboardData, setLeaderboardData] = useState([]);
  const currentURL = `${window.location.origin}/`;


  useEffect(() => {
    const fetchLeaderboardData = async () => {
      try {
        const usersCollectionRef = collection(db, "userss");
        const activityCollectionRef = collection(db, "Activity");

        // Query to limit top 3 users
        const usersQuery = query(usersCollectionRef);
        const usersSnapshot = await getDocs(usersQuery);
        const usersData = usersSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Fetch all activities
        const activitySnapshot = await getDocs(activityCollectionRef);
        const activityData = activitySnapshot.docs.map((doc) => doc.data());

        // Map user activities
        const userActivityCount = usersData.map((user) => {
          const activityCount = activityData.filter(
            (activity) => activity.username === user.username
          ).length;
          return {
            ...user,
            activityCount,
          };
        });

        // Sort by activity count in descending order
        const sortedLeaderboard = userActivityCount.sort(
          (a, b) => b.activityCount - a.activityCount
        );

        setLeaderboardData(sortedLeaderboard);
      } catch (error) {
        console.error("Error fetching leaderboard data:", error);
      }
    };

    fetchLeaderboardData();
  }, []);

  return (
    <>
      <span id="Leaderboard"></span>
      <div className="lg:w-[80%] w-[100%] pt-10">
        <h1 className="text-center text-3xl font-bold dark:text-white text-gray-800">
          Leaderboard
        </h1>
        <h1 className="text-center dark:text-white text-gray-800">
          Meet our top activity leaders for the week
        </h1>
        <div className="p-6 space-y-6">
          {leaderboardData.slice(0, 4).map((user, index) => (
            <Link
            to={`${currentURL}${user?.username}`}
            onClick={() => {
              window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
            }}  
              key={user.id}
              className={`flex items-center justify-between rounded-xl p-4 ${
                index === 0
                  ? "bg-yellow-300 text-gray-800"
                  : index === 1
                  ? "bg-gray-300 text-gray-800"
                  : "bg-yellow-700 text-white"
              } shadow-lg`}
            >
              <div className="flex items-center space-x-4">
              {user?.profileImage  ? (
            <img
              src={user?.profileImage || "https://via.placeholder.com/50"}
              alt={`${user?.username || "User"}'s Avatar`}
              className="w-12 h-12 rounded-full border-2 border-white"
            />
          ) : (
            <div className="w-12 h-12 rounded-full bg-gray-400 text-white flex items-center justify-center text-2xl">
              {user?.username[0]?.toUpperCase()}
            </div>
          )}
                <div className="items-center text-start">
                  <h3 className="text-lg font-semibold">
                    {user?.username || "Anonymous"}
                  </h3>
                  <p className="text-sm"> Credit: {user?.balance?.toLocaleString()} Taps: {user?.activityCount?.toLocaleString()}</p>
                </div>
              </div>
              <div>
                <span className="text-2xl font-bold">#{index + 1}</span>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </>
  );
};

export default Leaderboard;
