import React, { useEffect, useRef, useState } from "react";
// import Swiper from "swiper";
import { Swiper } from 'swiper/react';
import Homes from "./Homes";
import { Link } from "react-router-dom";
import "swiper/swiper-bundle.css";
// import Nav from "../component/Nav";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import vid1 from "./Asset/nfco.mp4"



// const firebaseConfig = {
//   // Your Firebase configuration
//   apiKey: "AIzaSyCRSVN6rIpkPQKLz77NFcES36Fpe665T9k",
//   authDomain: "nextberries-cb5b9.firebaseapp.com",
//   projectId: "nextberries-cb5b9",
//   storageBucket: "nextberries-cb5b9.appspot.com",
//   messagingSenderId: "44749615279",
//   appId: "1:44749615279:web:0b3e110f248c8e8b3ae45a",
//   measurementId: "G-9QZ27VRVHL",
//   // ...
// };

const firebaseConfig = {
  apiKey: "AIzaSyAvfSZiORsVohwIB696oyJYwxBcuh-jRRY",
  authDomain: "markers-digital-pro.firebaseapp.com",
  projectId: "markers-digital-pro",
  storageBucket: "markers-digital-pro.appspot.com",
  messagingSenderId: "99311584893",
  appId: "1:99311584893:web:63167d001bb5c569f31314",
  measurementId: "G-H43QCYF1XV"
};

// Initialize Firebase
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const Shop = ({title, name}) => {
  const trandingSliderRef = useRef(null);
  const [sliderData, setSliderData] = useState([]);

  useEffect(() => {
    const fetchSliderData = async () => {
      const db = firebase.firestore();
      const collection = db.collection("Products");

      try {
        const snapshot = await collection.get();
        const data = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setSliderData(data);

        const trandingSlider = new Swiper(trandingSliderRef.current, {
          // Initialize Swiper instance
        //   direction: "horizontal",
        // effect: "contain",
        // grabCursor: true,
        // centeredSlides: true,
        // loop: true,
        // slidesPerView: "auto",
        // coverflowEffect: {
        //   rotate: 0,
        //   stretch: 0,
        //   depth: 100,
        //   modifier: 2.5,
        // },
        // pagination: {
        //   el: ".swiper-pagination",
        //   clickable: true,
        // },
        // navigation: {
        //   nextEl: ".swiper-button-next",
        //   prevEl: ".swiper-button-prev",
        // },
        // autoplay: {
        //   delay: 500,
        //   disableOnInteraction: false,
        // },
          // ...
        });

        const timer = setInterval(() => {
          if (trandingSlider && trandingSliderRef.current) {
            trandingSlider.slideNext();
          }
        }, 2000); // Adjust the interval value (in milliseconds) for auto sliding

        return () => {
          clearInterval(timer);
          if (trandingSlider) {
            trandingSlider.destroy();
          }
        };
      } catch (error) {
        console.log("Error fetching slider data:", error);
      }
    };

    fetchSliderData();
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    setIsIconClosed(!isIconClosed);
  };

  const [isOpen, setIsOpen] = useState(false);
  const [isIconClosed, setIsIconClosed] = useState(false);
  const [hasScrolled, setHasScrolled] = useState(false);

  return (
    <div className="dark:bg-gradient-to-r from-black to-yellow-900 bg-white items-center justify-center h-[100%] w-[100%]">
      {/* <div
  // style={{width: '100%'}}
  className="w-[100%]  h-[100%] absolute justify-center items-center object-cover"
  dangerouslySetInnerHTML={{
    __html: `<video  style="
    width: 100%;
    top: 0px;
    left: 0px;
    right: 0px;
    height: 100%;
    object-fit: cover;
    // z-index: -1;" 
    autoplay loop muted playsinline>
    <source src=${vid1} type="video/mp4" />
    Your browser does not support the video tag.
    </video>`
  }}
  // <source src=${"https://web-video-resource.gentlemonster.com/assets/stories/jentle-salon/teasing/main/video-src-pc.mp4"} type="video/mp4" />
/>  */}
      <div 
      // className=" bg-no-repeat bg-cover "
      >
       
        <div 
            // style={{backgroundImage: `url(${Son})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}
        className=" h-[100%] w-[100%] "
        >
          <Homes title={title} name={name} />
        </div>
      </div>

      {/* <div className="mb-[20%]"/> */}
    </div>
  );
};

export default Shop;
