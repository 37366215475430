// Register.js
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { auth, signInWithGoogle } from "../firebaseConfig";
import { FcGoogle } from "react-icons/fc";
import logo1 from "./Asset/NEW YORK LOGO-04.jpg";
import logo from "./Asset/nfc.png";
import log from "./Asset/nfc.png";
import { doc, setDoc, getDoc, addDoc, collection, updateDoc } from "firebase/firestore";
import { db, firebase } from "../firebaseConfig"; // Import Firestore instance
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";
import vid from "./Asset/read.mp4"


const Register = () => {
  
  const [user, setUser] = useState(null);

  const [CSuser, setCSUser] = useState();
  const [status, setStatus] = useState("");
  const [Show, setShow] = useState(true);
  // const { uid, email, usernames } = location.state || {};  // Destructure email as well
  const currentUser = auth.currentUser;
  const currentURL = `${window.location.origin}/`;
  const [profileImage, setProfileImage] = useState(null);
  
  const [profileData, setProfileData] = useState(null);

  useEffect(() => {
    // Check if user data exists in local storage
    const userData = localStorage.getItem("userData");
    if (userData) {
      setUser(JSON.parse(userData));
    }
  }, []);

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe(); // Cleanup the event listener on unmount
  }, []);

  const writeToNFC = async () => {
    try {
      // console.log(`${currentURL}${username || profileData?.username}`)
      // Check if Web NFC is supported
      if (typeof window !== "undefined" && "NDEFReader" in window) {
        const ndef = new window.NDEFReader();

  
        // Start NFC session
        await ndef.write(`${currentURL}${username || profileData?.username}` || "Hello, NTAG DNA 424!");
  
        setStatus("Data written successfully to the NFC tag!");
        navigate("/profile");

      } else {
        setStatus("Please register by tapping the V-Train Logo on your garment like below");
        // setStatus("Web NFC is not supported in this browser.");
      }
    } catch (error) {
      console.error("Error writing to NFC:", error);
      setStatus("Failed to write data to NFC tag.");
    }
  };




  const location = useLocation();
  const navigate = useNavigate();
  const [Man, setMan] = useState(true);
  const [Boy, setBoy] = useState(true);
  const [Loading, setLoading] = useState(false);
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [Note, setNote] = useState(true);
  const [isLogin, setIsLogin] = useState(true); // Toggle between Login and Signup

  // const { username } = location.state || {};

  
  const handleGoogleSignIn = async () => {
    try {
      const user = await signInWithGoogle();
      if (user) {
        // Call the common initialize function
        await initializeUser(user);
      }
    } catch (error) {
      console.error("Error during Google sign-in:", error);
    }
  };
  
  const handleSignup = async () => {
    setLoading(true)
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
  
      // Call the common initialize function
      await initializeUser(user);
    } catch (error) {
      console.error("Error during email/password signup:", error);
      setError(error.message);
      setLoading(false)
    }
  };

    // Handle Login
    const handleLogin = async () => {
      setLoading(true)
      try {
        await signInWithEmailAndPassword(auth, email, password);
        setMessage("Login successful!");
        setLoading(false)
        navigate("/profile");
      } catch (err) {
        setError(err.message);
        setLoading(false)
      }
    };

   // Handle Password Reset
   const handlePasswordReset = async () => {
    try {
      await sendPasswordResetEmail(auth, email);
      setMessage("Password reset email sent. Please check your inbox.");
    } catch (err) {
      setError(err.message);
    }
  };
  
  const initializeUser = async (user) => {
    const { email, uid, displayName } = user;
    const userRef = doc(db, "userss", uid);
  
    try {
      const userDoc = await getDoc(userRef);
  
      if (!userDoc.exists()) {
        // Create user profile in Firestore
        await setDoc(userRef, {
          email,
          name: displayName || "New User",
          uid,
          createdAt: new Date(),
          username: auth?.currentUser?.email.split('@')[0], // Placeholder for username
          balance: 0,
        });
  
        // Predefined titles and logos for the Links sub-collection
        const linksData = [
          { name: "Website", image: "https://cdn0.iconfinder.com/data/icons/iVista2/256/Globe_Disconnected.png" },
          { name: "Instagram", image: "https://cdn2.iconfinder.com/data/icons/social-icons-33/128/Instagram-512.png" },
          { name: "PayPal", image: "https://cdn4.iconfinder.com/data/icons/logos-and-brands/512/250_Paypal_logo-512.png" },
          { name: "Facebook", image: "https://cdn1.iconfinder.com/data/icons/logotypes/32/square-facebook-512.png" },
          { name: "TikTok", image: "https://cdn0.iconfinder.com/data/icons/logos-brands-7/512/TikTok_logo_app0-512.png" },
          { name: "Zelle", image: "https://play-lh.googleusercontent.com/F4U2pL8z-Ic5FzCfe1xVXMWRvff6oEBIzDsyGRc4mE3bIUPiCfhuXXXvTOfcpVglKqs" },
          { name: "LinkedIn", image: "https://cdn2.iconfinder.com/data/icons/social-media-2285/512/1_Linkedin_unofficial_colored_svg-512.png" },
          { name: "CashApp", image: "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c5/Square_Cash_app_logo.svg/1200px-Square_Cash_app_logo.svg.png" },
          { name: "X", image: "https://freepnglogo.com/images/all_img/1725374683twitter-x-logo.png" },
          { name: "WhatsApp", image: "https://cdn2.iconfinder.com/data/icons/social-media-applications/64/social_media_applications_23-whatsapp-512.png" },
          { name: "Youtube", image: "https://cdn4.iconfinder.com/data/icons/logos-and-brands/512/395_Youtube_logo-512.png" },
          { name: "VCard Link", image: "https://cdn2.iconfinder.com/data/icons/interface-vol-2-1/16/link-chain-hyperlink-web-512.png" },
          { name: "Link", image: "https://cdn2.iconfinder.com/data/icons/interface-vol-2-1/16/link-chain-hyperlink-web-512.png" },
          { name: "Link", image: "https://cdn2.iconfinder.com/data/icons/interface-vol-2-1/16/link-chain-hyperlink-web-512.png" },
          { name: "Link", image: "https://cdn2.iconfinder.com/data/icons/interface-vol-2-1/16/link-chain-hyperlink-web-512.png" },
        ];
  
        // Add predefined links to the Links sub-collection
        const linksCollectionRef = collection(db, "userss", uid, "Links");
        for (const link of linksData) {
          await addDoc(linksCollectionRef, {
            name: link.name,
            url: "",
            image: link.image,
            createdAt: new Date(),
          });
        }

        setLoading(false)
  
        // Navigate to username input screen
        navigate("/username-input", { state: { uid } });
      } else {
        // User already exists, navigate to profile
        navigate("/profile", { state: { uid } });
      }
    } catch (error) {
      console.error("Error initializing user:", error);
    }
  };

   
   // fetch the user data from firebase
   useEffect(() => {
    const subscriber = db
        .collection('userss')
        .doc(currentUser?.uid)
        .onSnapshot(documentSnapshot => {
          setProfileData(documentSnapshot.data());
        });
    
    // Stop listening for updates when no longer required
    return () => subscriber();
  }, [currentUser, profileData]);

  const [username, setUsername] = useState("");

  const handleSaveUsername = async () => {
    setStatus('Loading..')
    const finalUsername = username || profileData?.username;
  
    // Validate that we have a username and user ID
    if (!currentUser?.uid || !finalUsername) {
      setError("Please provide a valid username.");
      return;
    }
  
    try {
      // Check if the username is already taken
      const usedUsernameDoc = await getDoc(doc(db, "usedUsernames", finalUsername));
      if (usedUsernameDoc.exists() && usedUsernameDoc.data()?.uid !== currentUser.uid) {
        setError("Username is already taken. Please choose a different one.");
        return;
      }
  
      // Save the username in the user's document
      await updateDoc(doc(db, "userss", currentUser.uid), {
        username: finalUsername
      });
  
      // Add the username to the usedUsernames collection to reserve it
      await setDoc(doc(db, "usedUsernames", finalUsername), {
        uid: currentUser.uid,
        timestamp: new Date()
      });
  
      // Call the image upload handler if defined
      // if (typeof handleImageUpload === "function") {
      //   await handleImageUpload();
      // }
  
      writeToNFC()
      // Navigate to the profile screen
    } catch (error) {
      console.error("Error updating username:", error);
      setError("An error occurred while saving your username.");
    }
  };

  
  return (
    <>
    <div className="min-h-screen items-center dark:bg-black dark:text-white  flex flex-col justify-center text-center bg-white">
  {/* Image visible in dark mode */}
  {/* <img src={log} className="hidden dark:inline w-60" alt="Dark Mode Logo" /> */}

  {/* Image visible in light mode */}
  {/* <img src={logo} className="inline dark:hidden w-60" alt="Light Mode Logo" /> */}

      {username && (
        <h2 className=" mt-5 text-4xl font-bold">
          Claim <b>@{username}</b> as your digital twin today
        </h2>
      )}

      {
        Boy ?
      <>
{
Man ?
      <>
<p className=" text-6xl font-extrabold cursor-pointer">Login</p>
            <input
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Enter email"
        className="px-4 py-2 mb-4 mt-5 dark:text-white dark:bg-[#111] h-12 border w-80 outline-blue-400 rounded-full"
      />
      {message && <p className="message text-red-500 mt-2">{message}</p>}
      {/* <p className="text-sm mb-4 mt-10">Register for free</p> */}
      {/* Registration form goes here */}
      <button onClick={() => {
        if(email){
          setBoy(!Boy)
          setNote(false)
          setMessage("")
        }else{
          setMessage("Please enter an email address")
        }
      }} className="px-10 shadow-lg py-3 mt-0 w-80 bg-gray-200 text-gray-700 rounded-full">
        {/* <FcGoogle className="inline mr-2" size={30} />  */}
        Continue
      </button>
      <p className="text-sm mt-5 cursor-pointer">Don't have an account ? <span onClick={() => setMan(!Man)} className="text-blue-500 cursor-pointer">Sign Up</span></p>
      <button onClick={handleGoogleSignIn} className="px-10 shadow-lg py-3 mt-5 w-80 bg-gray-200 text-gray-700 rounded-full">
        <FcGoogle className="inline mr-2" size={30} /> Continue with Google
      </button>
      {/* <p onClick={() => alert("Please use google for now")} className="text-sm mb-4 mt-5 cursor-pointer">Other login options</p> */}
        </>
        :
      <>
<p className="mt-5 text-6xl font-extrabold cursor-pointer">Sign Up</p>
            <input
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Enter email"
        className="px-4 py-2 mb-4 mt-5 dark:text-white dark:bg-[#111] h-12 border w-80 outline-blue-400 rounded-full"
      />

{message && <p className="message text-red-500 mt-2">{message}</p>}

      {/* <p className="text-sm mb-4 mt-10">Register for free</p> */}
      {/* Registration form goes here */}
      <button onClick={() => {
        if(email){

          setBoy(!Boy)
          setMessage("")
        }else{
          setMessage("Please enter an email address")
        }

      }} className="px-10 shadow-lg py-3 mt-0 w-80 bg-gray-200 text-gray-700 rounded-full">
        {/* <FcGoogle className="inline mr-2" size={30} />  */}
        Continue
      </button>
      <p className="text-sm mt-5 cursor-pointer">Already have an account ? <span onClick={() => setMan(!Man)} className="text-blue-500 cursor-pointer">Login</span></p>
      <button onClick={handleGoogleSignIn} className="px-10 shadow-lg py-3 mt-5 w-80 bg-gray-200 text-gray-700 rounded-full">
        <FcGoogle className="inline mr-2" size={30} /> Continue with Google
      </button>
      {/* <p onClick={() => alert("Please use google for now")} className="text-sm mb-4 mt-5 cursor-pointer">Other login options</p> */}
        </>
}
      </>
      :
      <>
{
      <>
<p className="mt-5 text-6xl font-extrabold cursor-pointer">Password</p>
<input
        type={showPassword ? "text" : "password"}
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        placeholder="Enter password"
        className="px-4 py-2 mb-4 mt-5 dark:text-white w-80 lg:w-[35%] dark:bg-[#111] h-12 border outline-blue-400 rounded-full"
      />
      <button
        type="button"
        onClick={() => setShowPassword(!showPassword)}
        className="absolute right-14 lg:right-1/3 top-1/2 transform -translate-y-1/2 text-gray-500"
      >
        {showPassword ? "Hide" : "Show"}
      </button>

      {message && <p className="message">{message}</p>}
      {/* <p className="text-sm mb-4 mt-10">Register for free</p> */}
      {/* Registration form goes here */}
      <button onClick={Note ? handleSignup : handleLogin} className="px-10 shadow-lg py-3 mt-0 w-80 bg-gray-200 text-gray-700 rounded-full">
        {/* <FcGoogle className="inline mr-2" size={30} />  */}
        {Loading ? "Loading.." : "Continue"}
      </button>
      {error && <p className="message text-red-500 mt-2">{error}</p>}
      <p className="text-sm mt-5 cursor-pointer"><span onClick={handlePasswordReset} className="text-blue-500 cursor-pointer">Forgot Password</span></p>
      {message && <p className="message mt-2">{message}</p>}

        </>

}
      </>
      }


    </div> 

    </>
  );
};

export default Register;
