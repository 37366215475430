import React, { useState } from "react";

const NFCToolsGuide = () => {
  const [activeStep, setActiveStep] = useState(null);

  const steps = [
    {
      title: "Step 1: Download the NFC Tools App",
      details: "Open the Google Play Store (for Android) or the Apple App Store (for iOS). Search for 'NFC Tools' and tap the download or install button to add the app to your device.",
    },
    {
      title: "Step 2: Open the NFC Tools App",
      details: "Locate the NFC Tools app on your phone's home screen or app list. Tap to open it.",
    },
    {
      title: "Step 3: Write Data to your garment (NFC Tag)",
      details:
        "1. Place the V-Train logo on garment near your phone.\n2. Tap on 'Write' in the NFC Tools app.\n3. Add the data you want to write (e.g., https://www.vfawear2earn.com/byurachek ).\n4. Tap 'Write' again and hold the V-Train logo on garment near your phone until the process completes.",
    },
    {
      title: "Step 4: Read Data",
      details:
        "1. Tap 'Read' in the NFC Tools app.\n2. Hold the V-Train logo on your garment near your phone.\n3. Wait for the app to display the information stored on the tag.",
    },
  ];

  const toggleStep = (index) => {
    setActiveStep(activeStep === index ? null : index);
  };

  return (
    <div className="lg:pt-20 pt-14">
    <div className="min-h-screen py-8 px-4">
      <div className="max-w-3xl mx-auto bg-white shadow-lg rounded-lg p-6">
        <h1 className="text-2xl font-bold text-gray-800 mb-4">
          Step-by-Step Guide: NFC Tools App
        </h1>
        <p className="text-gray-600 mb-6">
          Follow these steps to download and use the NFC Tools app for reading and writing NFC tags.
        </p>
        <div className="space-y-4">
          {steps.map((step, index) => (
            <div
              key={index}
              className="border border-gray-300 rounded-lg p-4 cursor-pointer bg-gray-50 hover:bg-gray-100"
              onClick={() => toggleStep(index)}
            >
              <div className="flex justify-between items-center">
                <h2 className="text-lg font-bold text-gray-800">{step.title}</h2>
                <span className="text-gray-500">
                  {activeStep === index ? "−" : "+"}
                </span>
              </div>
              {activeStep === index && (
                <p className="text-gray-600 mt-2 whitespace-pre-line">{step.details}</p>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
    </div>
  );
};

export default NFCToolsGuide;
