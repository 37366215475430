import { Route, Routes } from 'react-router-dom';
import Hero from './Components/Hero';
import Register from './Components/Register';
import UsernameInput from './Components/UsernameInput';
import Profile from './Components/Profile';
import Footer from './Components/Footer';
import { useEffect, useState } from 'react';
import Nav from './Components/Nav';
import ResponsiveMenu from './Components/ResponsiveMenu';
import Cookies from './Cookies';
import PublicProfile from './Components/PublicProfile';
import Footer2 from './Components/Footer2';
import Shop from './Components/Shop';
import ProductDetails from './Components/ProductDetails';
import Cart from './Components/Cart';
import ScrollToTop from './Components/ScrollToTop';
import Form from './Components/Form';
import SetUp from './Components/SetUp';
import Rewards from './Components/Rewards';
import RewardsPage from './Components/RewardsPage';
import CoverEdit from './Components/CoverEdit';
import Thank from './Components/Thank';
import NfcGuideScreen from './Components/Steps';

function App() {
  const [theme, setTheme] = useState(
    // localStorage.getItem("theme") ? localStorage.getItem("theme") : "dark"
    localStorage.getItem("theme") ? localStorage.getItem("theme") : "light"
  );
  const element = document.documentElement;

  useEffect(() => {
    if (theme === "dark") {
      element.classList.add("dark");
      localStorage.setItem("theme", "dark");
    } else {
      element.classList.remove("dark");
      localStorage.setItem("theme", "light");
    }
  }, [theme]);

  return (
    <div className='dark:bg-black text-center items-center'>
      <div className='text-center items-center flex flex-col'>
        <Nav theme={theme} setTheme={setTheme} />
      </div>
      <Routes>
        <Route path='/' element={<Hero theme={theme} setTheme={setTheme} />} />
        <Route path="/shop" element={<Shop />} />
        <Route path="/cart" element={<Cart />} />
        <Route
          path="/product-details/:productId"
          element={<ProductDetails />}
        />
        <Route path="/register" element={<Register theme={theme} />} />
        <Route path="/username-input" element={<UsernameInput theme={theme} />} />
        <Route path="/cover" element={<CoverEdit theme={theme} />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/form" element={<Form />} />
        <Route path="/thank" element={<Thank />} />
        <Route path='/:name' index element={<PublicProfile />}/>
        <Route path='/setup' index element={<SetUp />}/>
        <Route path='/guide' index element={<NfcGuideScreen />}/>
        <Route path='/rewards' index element={<Rewards />}/>
        <Route path='/rewardspage' index element={<RewardsPage />}/>
        <Route path='' element={<ResponsiveMenu theme={theme} setTheme={setTheme} />} />
      </Routes>
      {/* <div className='hidden lg:inline'> */}
      {/* <Cookies /> */}
      {/* </div> */}
      <ScrollToTop />
      <Footer2 />
    </div>
  );
}

export default App;
