import React from 'react'

export default function Thank() {
  return (
    <div className="min-h-screen flex flex-col justify-center items-cente\ bg-[#eee]">

        <h2 className="text-xl font-bold mb-2 mt-20">Thank you for your purchase your payment is recieved we'll reach out to you as soon as possible!</h2>
        <img src="https://i.pinimg.com/originals/51/ba/28/51ba287158fae33b9965806821e6b869.gif" alt="Thanks" className='self-center items-center object-center w-90 h-full' />

    </div>
  )
}
