import { useEffect, useState } from "react";
import {
  collection,
  query,
  where,
  orderBy,
  getDocs,
  onSnapshot,
  doc,
  setDoc,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";
import { db, auth } from "../firebaseConfig";
import Leaderboard from "./Leaderboard";
import RewardsPage from "./RewardsPage";

function Rewards() {
  const [leaderboardData, setLeaderboardData] = useState([]);
  const [activity, setActivity] = useState([]);
  const [friendRequests, setFriendRequests] = useState([]);
  const [friends, setFriends] = useState([]);
  const [profileData, setProfileData] = useState(null);
  const [activeTab, setActiveTab] = useState("Activity");
  const currentUser = auth.currentUser;

  useEffect(() => {
    // Fetch top users for the leaderboard
    const usersCollectionRef = collection(db, "userss");
    const topUsersQuery = query(usersCollectionRef, orderBy("balance", "desc"));

    const unsubscribe = onSnapshot(topUsersQuery, (snapshot) => {
      const topUsers = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setLeaderboardData(topUsers);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (!currentUser?.uid) return;

    // Fetch profile data
    const unsubscribe = onSnapshot(
      doc(db, "userss", currentUser.uid),
      (documentSnapshot) => {
        setProfileData(documentSnapshot.data());
      }
    );

    return () => unsubscribe();
  }, [currentUser]);

  useEffect(() => {
    if (!currentUser?.uid) return;

    // Fetch friends
    const friendsRef = collection(
      db,
      "userss",
      currentUser.uid,
      "friends"
    );
    const friendsQuery = query(friendsRef, where("status", "==", "accepted"));

    const unsubscribeFriends = onSnapshot(friendsQuery, (snapshot) => {
      const friendList = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setFriends(friendList);
    });

    // Fetch friend requests
    const requestsQuery = query(
      friendsRef,
      where("status", "==", "pending")
    );

    const unsubscribeRequests = onSnapshot(requestsQuery, (snapshot) => {
      const requests = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setFriendRequests(requests);
    });

    return () => {
      unsubscribeFriends();
      unsubscribeRequests();
    };
  }, [currentUser]);

  const acceptFriendRequest = async (request) => {
    try {
      const currentUserRef = doc(
        db,
        "userss",
        currentUser.uid,
        "friends",
        request.uid
      );
      const requesterRef = doc(
        db,
        "userss",
        request.uid,
        "friends",
        currentUser.uid
      );

      // Update statuses
      await updateDoc(currentUserRef, { status: "accepted" });
      await setDoc(requesterRef, {
        displayName: currentUser.displayName,
        photoURL: currentUser.photoURL,
        uid: currentUser.uid,
        position: profileData?.position || "Unknown",
        createdAt: new Date(),
        status: "accepted",
      });

      console.log("Friend request accepted");
    } catch (error) {
      console.error("Error accepting friend request:", error);
    }
  };

  const declineFriendRequest = async (request) => {
    try {
      const currentUserRef = doc(
        db,
        "userss",
        currentUser.uid,
        "friends",
        request.uid
      );
      await deleteDoc(currentUserRef);
      console.log("Friend request declined");
    } catch (error) {
      console.error("Error declining friend request:", error);
    }
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  
  useEffect(() => {
    if (!profileData?.username) return; // Avoid running if username is not available

    const usersCollectionRef = collection(db, "Activity");
    const userQuery = query(
      usersCollectionRef,
      where("username", "==", profileData.username),
      orderBy("timestamp", "desc") // Add order by for sorting
    );

    const unsubscribe = onSnapshot(userQuery, (snapshot) => {
      const filteredActivity = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setActivity(filteredActivity);
    });

    return () => unsubscribe(); // Cleanup on component unmount or dependency change
  }, [profileData?.username]);

        // Format the timestamp (assuming it's a Firestore Timestamp)
        const formatTimestamp = (timestamp) => {
          if (!timestamp) return ""; // Handle undefined or null
          try {
            const date = timestamp.toDate ? timestamp.toDate() : new Date(timestamp); // Convert Firestore Timestamp to Date
            return date.toLocaleString(); // Format as a local date/time string
          } catch (error) {
            console.error("Error formatting timestamp:", error);
            return ""; // Fallback for invalid timestamps
          }
        };
      

  return (
    <div className="dark:bg-gradient-to-r from-black to-yellow-900 bg-white pt-20 lg:pt-24 dark:text-white text-black h-full flex flex-col">
      {/* Header */}
      <div className="justify-between items-center text-start lg:pl-20">
      <div className=" justify-between items-center text-start lg:pl-20">
      <div className="flex justify-between items-center text-start p-4 ">
        <div className="flex items-center">
        <div className="flex justify-center items-center mr-2">
          {/* <p className="font-semibold m-2 text-sm dark:text-[#ccc]">{`Token balance ${profileData.balance}`}  </p> */}
          {profileData?.profileImage ? (
            <img
            src={profileData?.profileImage}
            alt="Profile"
            className="w-24 h-24 rounded-full object-cover"
            />
          ) : (
            <div className="w-24 h-24 rounded-full bg-gray-400 text-white flex items-center justify-center text-2xl">
              {profileData?.username[0]?.toUpperCase()}
            </div>
          )}
        </div>
                  <div className="lg:flex">
            <div className="grid">
            <h3 className="text-xl">{profileData?.username}</h3>
            <p className="text-sm mb-1">{activity?.length} connections</p>
            </div>

            <div className="grid lg:ml-2">

          <a
            // type="primary"
            // onClick={() => navigate("/username-input", { state: { uid, email, usernames } })}
            href={"#leader"}
            className="bg-gradient-to-r from-blue-600 to-yellow-600 border-none px-4 text-sm p-1 mb-1 text-center rounded-lg text-white"
            >
            View Leaderboard
          </a>
          <a
            // type="primary"
            // onClick={() => navigate("/username-input", { state: { uid, email, usernames } })}
            href={"#rewards"}
            className="bg-gradient-to-r from-blue-600 to-yellow-600 border-none px-4 text-sm p-1 text-center rounded-lg text-white"
            >
            Claim Reward
          </a>
              </div>
          </div>
        </div>
    
      </div>
      </div>

        {/* Tabs */}
        <div className="flex mb-2 space-x-4 border-b-2 border-gray-300">
          {["Activity", "Friends", "Friend Requests"].map((tab) => (
            <button
              key={tab}
              className={`pb-2 ${
                activeTab === tab
                  ? "border-b-2 px-8 dark:border-white border-gray-600"
                  : "text-gray-600 hover:text-gray-800"
              }`}
              onClick={() => handleTabClick(tab)}
            >
              {tab}
            </button>
          ))}
        </div>
      </div>

      {/* Content */}
      <div className="flex-1 p-4 lg:pl-20 overflow-y-auto">
        {activeTab === "Activity" &&
           activity.map((user, index) => (
            <div
              key={user.id}
            >
              <div className="flex items-center space-x-2 p-2 lg:ml-3">
                <img
                  src={user?.image || "https://static.vecteezy.com/system/resources/thumbnails/005/129/844/small_2x/profile-user-icon-isolated-on-white-background-eps10-free-vector.jpg"}
                  alt={`${user?.username || "User"}'s Avatar`}
                  className="w-6 h-6 rounded-full border-2 border-white"
                />
                  <span className="">{user?.pageVisited === "pay" && "Boom💥"} {user?.visitorEmail?.split('@')[0]} {user?.pageVisited === "pay" ? "paid through your V-Train" : "Just tapped your V-Train"} {user?.amount ? `earning you 🪙${user?.amount} credit` : ""}</span><span className="text-sm"> {formatTimestamp(user?.timestamp)}</span>
              </div>
            </div>
          ))}

        {activeTab === "Friends" &&
          (friends.length ? (
            friends.map((friend) => (
              <div key={friend.id} className="p-2 flex items-center">
                <img
                  src={friend.photoURL || "https://via.placeholder.com/50"}
                  alt={friend.displayName}
                  className="w-8 h-8 rounded-full"
                />
                <span className="ml-2">{friend.displayName}</span>
              </div>
            ))
          ) : (
            <p>No friends yet.</p>
          ))}

        {activeTab === "Friend Requests" &&
          (friendRequests.length ? (
            friendRequests.map((request) => (
              <div key={request.id} className="p-2 flex items-center">
                <img
                  src={request.photoURL || "https://via.placeholder.com/50"}
                  alt={request.displayName}
                  className="w-8 h-8 rounded-full"
                />
                <span className="ml-2">{request.displayName}</span>
                <button
                  onClick={() => acceptFriendRequest(request)}
                  className="ml-auto bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600"
                >
                  Accept
                </button>
                <button
                  onClick={() => declineFriendRequest(request)}
                  className="ml-2 bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600"
                >
                  Decline
                </button>
              </div>
            ))
          ) : (
            <p>No friend requests.</p>
          ))}
      </div>

      {/* Leaderboard and Rewards */}
      <span id="leader"></span>
<div className="items-center justify-center flex">
      {/* Navigation */}
      <Leaderboard />
</div>
      <span id="rewards"></span>
      <RewardsPage />
    </div>
  );
}

export default Rewards;
