import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import {loadStripe} from '@stripe/stripe-js';
import {
  PaymentElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { getFirestore, collection, addDoc, updateDoc, query, where, getDocs, doc  } from 'firebase/firestore';
import { auth, db } from '../firebaseConfig';
import { useLocation, useNavigate } from "react-router-dom";
import firebase from "../firebaseConfig";
import { message } from 'antd';




const CheckoutForm = ({productData, amount, name}) => {
  // console.log("Name:", name || "Oku");
  // console.log("Oku Okon user", name || "Oku")
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [delivery, setDelivery] = useState("");
  const [isPickup, setIsPickup] = useState(true);
  const [phone, setPhone] = useState("");
  const [profileData, setProfileData] = useState(null);
  const currentUser = auth.currentUser;
  const currentURL = `${window.location.origin}/`;
  const navigate = useNavigate();

  
  const [user, setUser] = useState(null);

  const [CSuser, setCSUser] = useState();
  useEffect(() => {
    
    // Check if user data exists in local storage
    const userData = localStorage.getItem("userData");
    if (userData) {
      setUser(JSON.parse(userData));
    }
  }, []);

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe(); // Cleanup the event listener on unmount
  }, []);




     // fetch the user data from firebase
     useEffect(() => {
      const subscriber = db
          .collection('userss')
          .doc(currentUser?.uid)
          .onSnapshot(documentSnapshot => {
            setProfileData(documentSnapshot.data());
          });
      
      // Stop listening for updates when no longer required
      return () => subscriber();
    }, [currentUser]);

    const url = currentURL + productData?.username;

  const [errorMessage, setErrorMessage] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (elements == null) {
      return;
    }

    // Trigger form validation and wallet collection
    const {error: submitError} = await elements.submit();
    if (submitError) {
      // Show error to your customer
      setErrorMessage(submitError.message);
      setLoading(false)
      return;
    }

    // Create the PaymentIntent and obtain clientSecret from your server endpoint
    const res = await fetch('/create-intent', {
      method: 'POST',
    });

    const {client_secret: clientSecret} = await res.json();

    const {error} = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      clientSecret,
      confirmParams: {
        return_url: 'https://vfawear2earn.com/thank',
      },
    });

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setErrorMessage(error.message);
      setLoading(false)
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

// Function to create data in the Payments collection
const createPayment = async (paymentData) => {
  try {
    // Create a new document in the Payments collection
    const docRef = await addDoc(collection(db, "Payments"), paymentData);
    console.log("Payment created with ID:", docRef.id);

    // Query the Users collection to find the user with the matching username
    const usersRef = collection(db, "userss");
    const q = query(usersRef, where("username", "==", name || "Oku"));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      querySnapshot.forEach(async (userDoc) => {
        // Update the credit balance for the matched user
        const userRef = doc(db, "userss", userDoc.id);
        const userData = userDoc.data();
        const newCreditBalance = (userData?.balance || 0) + 1000;

        await updateDoc(userRef, {
          balance: newCreditBalance,
        });

        console.log(`Updated credit balance for user ${name || "Oku"} to ${newCreditBalance}`);
        try {
          const visitorEmail = auth?.currentUser?.email || "Anonymous";
          const activityData = {
            username: name || "Oku",
            timestamp: new Date(),
            visitorEmail,
            pageVisited: "pay",
            amount: 1000,
            image: profileData?.profileImage,
            vuname: profileData?.username || "Anonymous"
          };
          await addDoc(collection(db, "Activity"), activityData);
        } catch (error) {
          console.error("Error logging activity:", error);
        }
      });
    } else {
      console.log(`No user found with username: ${name || "Oku"}`);
    }

    return docRef;
  } catch (error) {
    console.error("Error processing payment and updating user:", error);
    throw error;
  }
};

//  const createPayment = async (paymentData) => {
//   try {
//     const docRef = await addDoc(collection(db, 'Payments'), paymentData);
//     console.log('Payment created with ID:', docRef.id);
//     return docRef;
//   } catch (error) {
//     setLoading(false)
//     console.error('Error adding payment:', error);
//     throw error;
//   }
// };

// Function to save all attempts to a separate collection on "Buy Now" click
 const logBuyNowAttempt = async (paymentData) => {
  try {
    const docRef = await addDoc(collection(db, 'BuyAttempts'), paymentData);
    console.log('Buy Now attempt logged with ID:', docRef.id);
    return docRef;
  } catch (error) {
    setLoading(false)
    console.error('Error logging buy now attempt:', error);
    throw error;
  }
};


 // Create a payment (example data)
 const paymentData = {
  productData,
  delivery: delivery || "Pick Up",
  phone: phone,
  ptitle: productData?.title || "Cart Checkout",
  pid: productData?.id || "Cart Checkout",
  pimage: productData?.product_image || "https://www.vfawear2earn.com/static/media/vfa.2dd683d55cefa7668c42.png",
  amount: amount, // Replace with actual amount
  currency: 'USD',
  username: profileData?.username,
  userId: currentUser?.uid, // Replace with actual user ID
  userEmail: currentUser?.email, // Replace with actual user ID
  profileLink: url,
  timestamp: new Date(),
};

// Example usage in a React component
const handleBuyNowClick = async () => {
  setLoading(true)

  try {
    // Log the attempt
    await logBuyNowAttempt(paymentData);

    await handleSubmit()

    await createPayment(paymentData);
    console.log('Payment successful!');
    setLoading(false)
    // Navigate to the profile screen
    navigate("/thank");
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
  } catch (error) {
    console.error('Error handling Buy Now click:', error);
  }
};

// Example usage in a React component
const handleBuyNowClickBrian = async (event) => {
  event.preventDefault(); // Prevent form refresh
  setLoading(true)

  try {
    // Log the attempt
    await logBuyNowAttempt(paymentData);

    // await handleSubmit()

    await createPayment(paymentData);
    console.log('Payment successful!');
    setLoading(false)
    // Navigate to the profile screen
    navigate("/thank");
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
  } catch (error) {
    console.error('Error handling Buy Now click:', error);
  }
};


  return (
    <form 
    onSubmit={handleBuyNowClickBrian}
    // onSubmit={handleBuyNowClick}
    >
         {/* Delivery Address Fields */}
  {/* <div className="delivery-address">
    <label>
      Street Address:
      <input 
        type="text" 
        name || "Oku"="street" 
        required 
        className="input-field"
        placeholder="123 Main St" 
      />
    </label>
    <label>
      City:
      <input 
        type="text" 
        name || "Oku"="city" 
        required 
        className="input-field"
        placeholder="City" 
      />
    </label>
    <label>
      State:
      <input 
        type="text" 
        name || "Oku"="state" 
        required 
        className="input-field"
        placeholder="State" 
      />
    </label>
    <label>
      Postal Code:
      <input 
        type="text" 
        name || "Oku"="postalCode" 
        required 
        className="input-field"
        placeholder="Postal Code" 
      />
    </label>
  </div> */}

      <PaymentElement />
      {/* <button type="submit" disabled={!stripe || !elements}>
        Pay
      </button> */}
              {/* <div className="delivery-address text-sm lg:flex grid mt-5 text-start">
    <label>
      Delivery Address:
      <input 
        type="text" 
        name || "Oku"="street" 
        onChange={(e) => setDelivery(e.target.value)}
        required 
        className="border-1 p-2 border-gray-400"
        placeholder=" Enter 123 Main St" 
      />
    </label>
    <label>
      Phone Number:
      <input 
        type="text" 
        name || "Oku"="street" 
        onChange={(e) => setPhone(e.target.value)}
        required 
        className="border-1 p-2 border-gray-400"
        placeholder=" Enter Phone Number" 
      />
    </label>
    </div> */}
     <div className="delivery-section mt-3">
      <h3 className="text-lg font-bold">Pickup or Delivery</h3>
      
      <div className="option-toggle mt-2">
        <label className="mr-5">
          <input 
            type="radio" 
            name="deliveryOption" 
            value="pickup" 
            checked={isPickup} 
            onChange={() => setIsPickup(true)} 
          /> 
          &nbsp;Pickup at the Office       </label>
        
        <label>
          <input 
            type="radio" 
            name="deliveryOption" 
            value="delivery" 
            checked={!isPickup} 
            onChange={() => setIsPickup(false)} 
          /> 
        &nbsp;Deliver to My Address
        </label>
      </div>
      
      {isPickup && (
        <div className="pickup-info text-sm">
           <label className="block">
            Phone Number:
            <input 
              type="text" 
              name="phone" 
              value={phone} 
              onChange={(e) => setPhone(e.target.value)}
              required 
              className="border-1 p-2 px-20 m-2 shadow-lg rounded-lg border-gray-400"
              placeholder="Enter Phone Number" 
            />
          </label>
          <p><strong>Office Address for Pickup:</strong></p>
          <p>VFA Gallery</p>
          <p>151 Wooster Street</p>
          <p>New York, NY 10012</p>
          <p>UNITED STATES OF AMERICA</p>
        </div>
      )}

      {!isPickup && (
        <div className="delivery-address text-sm grid lg:flex mt-5 text-start">
          <label className="block mr-5">
            Delivery Address:
            <input 
              type="text" 
              name="street" 
              value={delivery} 
              onChange={(e) => setDelivery(e.target.value)}
              required 
              className="border-1 p-2 border-gray-400"
              placeholder="Enter 123 Main St" 
            />
          </label>
          
          <label className="block">
            Phone Number:
            <input 
              type="text" 
              name="phone" 
              value={phone} 
              onChange={(e) => setPhone(e.target.value)}
              required 
              className="border-1 p-2 border-gray-400"
              placeholder="Enter Phone Number" 
            />
          </label>
        </div>
      )}
    </div>
      <div className='items-center justify-center flex mt-5'>
        <p className='text-sm'>Secured by</p>
      <img className='w-20 ml-2' src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/ba/Stripe_Logo%2C_revised_2016.svg/2560px-Stripe_Logo%2C_revised_2016.svg.png" alt="Stripe" />
      </div>
      <button
      // type="submit" 
      // disabled={!stripe || !elements}
            // onClick={() => addItem(productData)}
            onClick={ () => user ? handleBuyNowClick() : message.warning('Please login to proceed with the purchase') }
            className="my-4 flex text-sm cursor-pointer w-[95%] ml-2.5 self-center text-center justify-center items-center hover:text-gray-50 hover:bg-gray-400 drop-shadow-2xl shadow-2xl py-4 px-6 bg-black text-white duration-500 rounded-full "
            // className="bg-dark-primary mt-2 mb-2 p-1 rounded-2xl bg-white  hover:bg-green-600 hover:text-white duration-500  text-green-600"
            style={{ borderRadius: "20", border: 0 }}
          >
            {/* <BsCartPlus size="1.8rem" className="" /> */}
           {loading ? " Loading.." : "Pay Now"}
          </button>
      {/* <button
      // type="submit" 
      // disabled={!stripe || !elements}
            // onClick={() => addItem(productData)}
            onClick={ () => user ? handleBuyNowClickBrian() : message.warning('Please login to proceed with the purchase') }
            className="my-4 flex text-sm cursor-pointer w-[95%] ml-2.5 self-center text-center justify-center items-center hover:text-gray-50 hover:bg-gray-400 drop-shadow-2xl shadow-2xl py-4 px-6 bg-black text-white duration-500 rounded-full "
            // className="bg-dark-primary mt-2 mb-2 p-1 rounded-2xl bg-white  hover:bg-green-600 hover:text-white duration-500  text-green-600"
            style={{ borderRadius: "20", border: 0 }}
          >
           {loading ? " Loading.." : "Brian free buy"}
          </button> */}
      {/* <button
      type="submit" disabled={!stripe || !elements}
            // onClick={() => addItem(productData)}
            className="my-4 flex text-sm cursor-pointer w-[95%] ml-2.5 self-center text-center justify-center items-center hover:text-gray-50 hover:bg-gray-400 drop-shadow-2xl shadow-2xl py-4 px-6 bg-black text-white duration-500 rounded-full "
            // className="bg-dark-primary mt-2 mb-2 p-1 rounded-2xl bg-white  hover:bg-green-600 hover:text-white duration-500  text-green-600"
            style={{ borderRadius: "20", border: 0 }}
          >
]            Pay Now
          </button> */}
      {/* Show error message to your customers */}
      {errorMessage && <div>{errorMessage}</div>}
    </form>
  );
};

export default CheckoutForm