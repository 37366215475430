import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { doc, updateDoc } from "firebase/firestore";
import { db, storage, auth } from "../firebaseConfig";
import { Button, Upload, message, Tooltip } from "antd";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";

const CoverEdit = () => {
  const navigate = useNavigate();
  const currentUser = auth?.currentUser;
  const [profileImage, setProfileImage] = useState(null);
  const [profileData, setProfileData] = useState(null);
  const [selectedColor, setSelectedColor] = useState(null);
  const [loading, setLoading] = useState(false);

  // Fetch profile data
  useEffect(() => {
    const subscriber = db
      .collection("userss")
      .doc(currentUser?.uid)
      .onSnapshot((documentSnapshot) => {
        setProfileData(documentSnapshot.data());
      });

    return () => subscriber();
  }, [currentUser]);

  const handleImageUpload = async () => {
    if (profileImage) {
      setLoading(true);
      try {
        const imageRef = ref(storage, `profileImages/cover/${currentUser.uid}`);
        await uploadBytes(imageRef, profileImage);
        const imageUrl = await getDownloadURL(imageRef);

        await updateDoc(doc(db, "userss", currentUser.uid), {
          profileCover: imageUrl,
          profileCoverColor: null, // Reset color when uploading an image
        });

        message.success("Image updated successfully!");
      } catch (error) {
        message.error("Error uploading image: " + error.message);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleColorSelection = async (color) => {
    try {
      setSelectedColor(color);
      await updateDoc(doc(db, "userss", currentUser.uid), {
        profileCoverColor: color,
        profileCover: null, // Reset image when choosing a color
      });

      message.success("Cover color updated successfully!");
    } catch (error) {
      message.error("Error updating color: " + error.message);
    }
  };

  const uploadProps = {
    beforeUpload: (file) => {
      setProfileImage(file);
      return false;
    },
    onRemove: () => {
      setProfileImage(null);
    },
  };

  const colors = [
    "#FF5733",
    "#33FF57",
    "#3357FF",
    "#F3FF33",
    "#FF33A8",
    "#33FFF6",
    "#D633FF",
    "#33FF99",
    "#FF9A33",
    "#FF3333",
  ];

  return (
    <div className="min-h-screen flex flex-col justify-center p-3 items-center dark:bg-black dark:text-white bg-white">
      <h2 className="text-2xl font-bold mb-2 mt-20">Change Cover Background</h2>

      <div className="flex justify-center items-center m-4">
        {profileData?.profileCover ? (
          <img
            src={profileData.profileCover}
            alt="Profile"
            className="w-40 h-60 rounded-lg object-cover"
          />
        ) : (
          <div
            style={{
              backgroundColor: selectedColor || profileData?.profileCoverColor || "#ccc",
            }}
            className="w-40 h-60 rounded-lg"
          />
        )}
      </div>

      {/* Upload Section */}
      <div className="text-center">
        <Upload {...uploadProps} listType="picture" maxCount={1}>
          <Button type="dashed" className="text-[#555]">
            Select Cover Photo
          </Button>
        </Upload>

        {profileImage && (
          <Button
            type="primary"
            className="bg-gradient-to-r from-violet-600 to-indigo-600 border-none text-white mt-4"
            onClick={handleImageUpload}
            loading={loading}
            disabled={!profileImage}
          >
            Upload Image
          </Button>
        )}
      </div>

      <div className="mt-8">
        <h3 className="font-bold mb-2">Choose a Background Color</h3>
        <div className="flex space-x-2">
          {colors.map((color) => (
            <Tooltip key={color} title={color}>
              <button
                className="w-10 h-10 rounded-full border-2"
                style={{ backgroundColor: color }}
                onClick={() => handleColorSelection(color)}
              ></button>
            </Tooltip>
          ))}
        </div>
      </div>

      <button
        onClick={() => navigate("/profile")}
        className="px-6 py-2 mt-5 w-60 border-2 rounded-full"
      >
        Skip
      </button>
    </div>
  );
};

export default CoverEdit;
